export default {
  inject: ['parent'],
  computed: {
    isActive() {
      return this.parent.activeItem === this
    },
  },
  created() {
    this.parent.addChildItem(this)
  },
  beforeDestroy() {
    this.parent.removeChildItem(this)
  },
  render(h) {
    const tabindex = this.parent.noBody ? '-1' : this.isActive ? '0' : '-1'
    const vnode = h(
      'div',
      {
        directives: [{ name: 'show', value: this.isActive }],
        attrs: { role: 'tabpanel', tabindex },
      },
      this.$slots.default
    )
    return h(
      'transition',
      {
        props: {
          name: 'ucsc-fade-absolute',
        },
      },
      [vnode]
    )
  },
}
