var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"Swiper",class:[
    'swiper',
    'ucsc-swiper-container',
    {
      'ucsc-swiper-container--overlay': _vm.overlay,
      'ucsc-swiper-container--overlay--vertical': _vm.overlayVertical,
      'ucsc-swiper-container--equal-heights': _vm.equalHeights,
      'ucsc-swiper-container--dots-outside': _vm.dotsPosition === 'outside',
      'ucsc-swiper-container--dots-inside': _vm.dotsPosition === 'inside',
      'ucsc-swiper-container--nav-aside': _vm.navPosition === 'aside',
      'ucsc-swiper-container--nav-aside-desktop':
        _vm.navPosition === 'aside-desktop',
      'ucsc-swiper-container--nav-bottomCenter':
        _vm.navPosition === 'bottom-center',
    },
  ]},[_c('div',{staticClass:"swiper-wrapper"},[_vm._t("default")],2),_vm._v(" "),(_vm.navigation)?[_vm._t("button-prev",function(){return [_c('div',{staticClass:"ucsc-swiper-nav-button swiper-button-prev",class:{
          'ucsc-swiper-nav-button--no-bg': _vm.navPosition === 'white-large',
        }},[_c('SvgIcon',{staticClass:"ucsc-arrow",class:{
            ' ucsc-arrow--tiny': _vm.navPosition !== 'white-large',
          },attrs:{"name":_vm.navPosition === 'bottom-center' || _vm.navPosition === 'white-large'
              ? 'angle-left-white'
              : 'angle-left-blue'}})],1)]}),_vm._v(" "),_vm._t("button-next",function(){return [_c('div',{staticClass:"ucsc-swiper-nav-button swiper-button-next",class:{
          'ucsc-swiper-nav-button--no-bg': _vm.navPosition === 'white-large',
        }},[_c('SvgIcon',{staticClass:"ucsc-arrow",class:{
            ' ucsc-arrow--tiny': _vm.navPosition !== 'white-large',
          },attrs:{"name":_vm.navPosition === 'bottom-center' || _vm.navPosition === 'white-large'
              ? 'angle-right-white'
              : 'angle-right-blue'}})],1)]})]:_vm._e(),_vm._v(" "),(_vm.pagination)?_c('div',{staticClass:"swiper-pagination"}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }