<template>
  <div class="ucsc-tabs">
    <div
      ref="tablist"
      role="tablist"
      :aria-label="rootAriaLabel"
      class="ucsc-tabs__nav"
    >
      <template v-for="(item, i) in childItems">
        <UcscTabSlot
          v-if="item.$scopedSlots.header"
          :key="item.$attrs.label"
          :component="item"
          :is-active="activeIndex === i"
          :click-action="() => (activeValue = item.$attrs.value)"
          :keydown-action="handleKeyDown"
        />
        <button
          v-else
          :key="item.$attrs.label + '1'"
          role="tab"
          class="ucsc-tabs__button ucsc-tabs__navItem ucsc-tabs__itemHeader-default ucsc-tabs__itemHeader-text"
          :aria-selected="activeIndex === i"
          :tabindex="activeIndex === i ? 0 : -1"
          :class="{
            'ucsc-tabs__itemHeader--active-default ucsc-tabs__button--active':
              activeIndex === i,
          }"
          @click="() => (activeValue = item.$attrs.value)"
          @keydown="handleKeyDown"
        >
          {{ item.$attrs.label }}
        </button>
      </template>
    </div>

    <section class="ucsc-tabs__content">
      <slot />
    </section>
  </div>
</template>

<script>
// Static assets
import isNil from 'lodash/isNil'

// Components
import UcscTabSlot from '~/components/UcscTabSlot'

export default {
  components: {
    UcscTabSlot,
  },
  provide() {
    return {
      parent: this,
    }
  },
  props: {
    rootAriaLabel: {
      type: String,
      default: 'Sezioni',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    noBody: {
      type: Boolean,
      defaul: false,
    },
  },
  data() {
    return {
      childItems: [],
      activeValue: this.value,
    }
  },
  computed: {
    activeIndex() {
      return this.childItems.findIndex((item) => item === this.activeItem)
    },
    activeItem() {
      return isNil(this.activeValue)
        ? this.childItems[0]
        : this.childItems.find((i) => i.$attrs.value === this.activeValue)
    },
  },
  watch: {
    activeItem(newItem, oldItem) {
      if (!oldItem || oldItem.$attrs.value === newItem.$attrs.value) return
      this.$emit('input', newItem.$attrs.value)
    },
  },
  methods: {
    addChildItem(item) {
      this.$nextTick(() => {
        this.childItems.push(item)
      })
    },
    removeChildItem(item) {
      this.$nextTick(() => {
        this.childItems = this.childItems.filter((i) => i !== item)
      })
    },

    handleKeyDown(event) {
      const maxIndex = this.childItems.length - 1
      let index
      switch (event.key) {
        case 'ArrowLeft':
          event.preventDefault()
          event.stopPropagation()
          index = this.activeIndex === 0 ? maxIndex : this.activeIndex - 1
          this.activeValue = this.childItems[index].$attrs.value
          break
        case 'ArrowRight':
          event.preventDefault()
          event.stopPropagation()
          index = this.activeIndex === maxIndex ? 0 : this.activeIndex + 1
          this.activeValue = this.childItems[index].$attrs.value
          break
        case 'Home':
          event.preventDefault()
          event.stopPropagation()
          this.activeValue = this.childItems[0].$attrs.value
          break
        case 'End':
          event.preventDefault()
          event.stopPropagation()
          this.activeValue = this.childItems[maxIndex].$attrs.value
      }

      // Focuses the correct button (now with tabindex="0")
      this.$refs.tablist.children[this.activeIndex].focus()
    },
  },
}
</script>
